export function UsernameColumn({ rowData }) {
  const name = rowData.user.name;

  let surname = '';

  if (rowData.user.attributes.last_name) {
    surname = rowData.user.attributes.last_name;
  }

  if (rowData.user.attributes.surname) {
    surname = rowData.user.attributes.surname;
  }

  let secondSurname = '';

  if (rowData.user.attributes.last_name_2) {
    secondSurname = rowData.user.attributes.last_name_2;
  }

  if (rowData.user.attributes.surname_2) {
    secondSurname = rowData.user.attributes.surname;
  }

  const fullname = `${name} ${surname} ${secondSurname}`;

  return <p>{fullname}</p>;
}
