import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CheckRole from '../../components/CheckRole';
import DataGridView from '../../components/DataGridView';
import ExportClinicCases from '../../components/ExportClinicCases';
import { H3, PageHeading } from '../../components/Headings';
import Modal from '../../components/Modal';
import ViewModeButton from '../../components/ViewModeButton';
import AppContext from '../../contexts/AppContext';
import useClinicCases from '../../hooks/useClinicCases';
import useForms from '../../hooks/useForms';
import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import useStates from '../../hooks/useStates';
import { DateColumn } from '../SharedComponents/DateColumn';
import { ActionsColumn } from './components/ActionsColumn';
import { StateColumn } from './components/StateColumn';
import { UsernameColumn } from './components/UserNameColumn';

function ClinicCases() {
  const { l } = useLocale();
  const { clinicCases, handleDelete, toggleViewMode, viewMode, getClinicCases } = useClinicCases();
  const { forms: formPermissions } = React.useContext(AppContext);
  const { states } = useStates(false);
  const { forms } = useForms({ paginate: false });
  const { viewStates, forms: userForms } = React.useContext(AppContext);
  const newCaseModal = useModal();

  const columns = [
    {
      header: l('title'),
      accessor: 'title',
      filtrable: true,
    },
    {
      header: l('form'),
      accessor: 'form.name',
      filtrable: true,
      filterType: 'select',
      options: [
        { value: '', label: l('all_forms') },
        ...forms
          .filter(form => userForms.includes(form._id))
          .map(form => ({ value: form._id, label: form.name })),
      ],
    },
    {
      header: l('user'),
      accessor: 'user.name',
      filtrable: true,
      component: UsernameColumn,
    },
    {
      header: l('state'),
      accessor: 'state.name',
      component: StateColumn,
      cellClass: 'text-center',
      filtrable: true,
      filterType: 'select',
      options: [
        { value: '', label: l('all_states') },
        ...states
          .filter(state => viewStates.includes(state._id))
          .map(state => ({ value: state._id, label: state.name })),
      ],
    },
    {
      header: l('creation_date'),
      accessor: 'createdAt',
      component: DateColumn,
    },
    {
      header: l('modification_date'),
      accessor: 'updatedAt',
      component: DateColumn,
    },
    {
      header: l('actions'),
      component: ({ rowData, page, limit }) => (
        <ActionsColumn rowData={rowData} handleDelete={id => handleDelete(id, page, limit)} />
      ),
      cellClass: 'flex gap-3 justify-center',
    },
  ];

  return (
    <>
      <PageHeading title={l('clinic_cases')} image="/img/clinic_case.svg">
        <CheckRole has={'export_clinic_cases'}>
          <ExportClinicCases />
        </CheckRole>
        <ViewModeButton viewMode={viewMode} toggle={toggleViewMode} />
        <CheckRole has="add_clinic_cases">
          <Button type="secondary" onClick={newCaseModal.openModal}>
            {l('add_new_clinic_case')}
          </Button>
        </CheckRole>
      </PageHeading>
      <Card>
        <DataGridView
          columns={columns}
          data={clinicCases.docs}
          getData={getClinicCases}
          totalPages={clinicCases.totalPages}
        />
      </Card>

      <Modal show={newCaseModal.showModal} onClose={newCaseModal.closeModal}>
        <H3>{l('select_form')}</H3>
        {forms
          .filter(form => formPermissions.includes(form._id))
          .map(form => (
            <>
              <div className={`px-3 py-2 border-gray-600 border rounded-md cursor-pointer mb-3`}>
                <Link key={form._id} to={`/form/${form._id}/answer`}>
                  {form.name}
                </Link>
              </div>
            </>
          ))}
      </Modal>
    </>
  );
}

export default ClinicCases;
